import { lazy } from 'react';

//import { BrowserRouter as  Route } from 'react-router-dom';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
// const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
// const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
// const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
// const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/customers/CustomerList')));
const Transactions = Loadable(lazy(() => import('views/transactions/TransactionsList')));
const MoneyCallbacks = Loadable(lazy(() => import('views/moneyCallbacks/MoneyCallbacksList')));
const Pricing = Loadable(lazy(() => import('views/pricing/PricingList')));
const Subscriptions = Loadable(lazy(() => import('views/subscriptions/Default')));

// sample page routing
// const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/dashboard',
      element: <DashboardDefault />
    },
    {
      path: '/customers',
      element: <UtilsTablerIcons />
    },
    {
      path: '/subscriptions',
      element: <Subscriptions />
    },
    {
      path: '/transactions',
      element: <Transactions />
    },
    {
      path: '/money-callbacks',
      element: <MoneyCallbacks />
    },
    {
      path: '/pricing',
      element: <Pricing />
    },
    // {
    //   path: 'dashboard',
    //   children: [
    //     {
    //       path: 'default',
    //       element: <DashboardDefault />
    //     }
    //   ]
    // },
    // {
    //   path: 'utils',
    //   children: [
    //     {
    //       path: 'util-typography',
    //       element: <UtilsTypography />
    //     }
    //   ]
    // },
    // {
    //   path: 'utils',
    //   children: [
    //     {
    //       path: 'util-color',
    //       element: <UtilsColor />
    //     }
    //   ]
    // },
    // {
    //   path: 'utils',
    //   children: [
    //     {
    //       path: 'util-shadow',
    //       element: <UtilsShadow />
    //     }
    //   ]
    // },
    // {
    //   path: 'icons',
    //   children: [
    //     {
    //       path: 'tabler-icons',
    //       element: <UtilsTablerIcons />
    //     }
    //   ]
    // },
    // {
    //   path: 'icons',
    //   children: [
    //     {
    //       path: 'material-icons',
    //       element: <UtilsMaterialIcons />
    //     }
    //   ]
    // },
    // {
    //   path: 'sample-page',
    //   element: <SamplePage />
    // }
  ]
};

// const MainRoutes = () => {
//   return (
//     <Route path="/" element={<MainLayout />}>
//       <Route path="dashboard" element={<DashboardDefault />} />
//       <Route path="tabler-icons" element={<UtilsTablerIcons />} />
//       {/* Add more routes here if needed */}
//     </Route>
//   );
// };

export default MainRoutes;
