const currentState = {
  authentication: {
    data: { authenticated: false, userId: null, firstName: null, secondName: null, userEmail: null, accessToken: null },
    error: null
  }
};

const reducer = (state = currentState, action) => {
  switch (action.type) {
    case 'INSTANTIATE_AUTHENTICATION': {
      const authentication = { ...state.authentication };

      if (null !== action.payload.authentication) authentication.data = action.payload.authentication;

      authentication.error = action.payload.error ?? false;

      return {
        ...state,
        authentication: authentication
      };
    }
    default:
      return state;
  }
};

export default reducer;
