import { useSelector } from 'react-redux';
import axios from 'axios';

import './AxiosGlobal';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import AuthContext from './contexts/AuthContext';

// ==============================|| APP ||============================== //

const App = () => {
  const customization = useSelector((state) => state.customization);
  const authState = useSelector((state) => state.authentication);
  axios.defaults.headers.common['Authorization'] = `Bearer ${authState.authentication.data.accessToken}`; 
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <AuthContext.Provider
            value={{authenticated: authState.authentication.data.authenticated,
              userId: authState.authentication.data.userId,
              firstName: authState.authentication.data.firstName,
              secondName: authState.authentication.data.secondName,
              userEmail: authState.authentication.data.userEmail,
              accessToken: authState.authentication.data.accessToken,}}
          >
            <Routes />
          </AuthContext.Provider>
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
