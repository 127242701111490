import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import authenticationReducer from './authReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  authentication: authenticationReducer
});

export default reducer;
