import React from 'react';

const authContext = React.createContext({
  authenticated: false,
  userId: null,
  firstName: null,
  secondName: null,
  userEmail: null,
  accessToken: null,
  
});

export default authContext;
