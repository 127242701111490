// axiosGlobalConfig.js

import axios from 'axios';

// Set base URL for your API requests
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

// Set default headers, such as authorization headers, content type, etc.
axios.defaults.headers.common['Cache-Control'] = 'no-store';
axios.defaults.headers.common['Pragma'] = 'no-cache';
axios.defaults.headers.post['Content-Type'] = 'application/json';

// You can also configure other defaults like timeout, response type, etc.
axios.defaults.timeout = 5000; // milliseconds

// Make axios available globally
window.axios = axios;
