// import dashboard from './dashboard';
// import pages from './pages';
// import utilities from './utilities';
// import other from './other';

import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import SettingsBackupRestoreOutlinedIcon from '@mui/icons-material/SettingsBackupRestoreOutlined';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import AssuredWorkloadOutlinedIcon from '@mui/icons-material/AssuredWorkloadOutlined';
// constant
const icons = {  AssuredWorkloadOutlinedIcon, PriceChangeOutlinedIcon, GroupOutlinedIcon ,  DashboardOutlinedIcon, PaidOutlinedIcon, SettingsBackupRestoreOutlinedIcon};

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  //items: [dashboard, pages, utilities, other]
  // items: [dashboard]
  items:[

      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        url: '/dashboard',
        icon: icons. DashboardOutlinedIcon,
        breadcrumbs: false
      },
      {
        id: 'customers',
        title: 'Customer List',
        type: 'item',
        url: '/customers',
        icon: icons. GroupOutlinedIcon,
        breadcrumbs: false
      },
      {
        id: 'subscriptions',
        title: 'Subscriptions',
        type: 'item',
        url: '/subscriptions',
        icon: icons. AssuredWorkloadOutlinedIcon,
        breadcrumbs: false
      }, 
      {
        id: 'transactions',
        title: 'Transactions',
        type: 'item',
        url: '/transactions',
        icon: icons. PaidOutlinedIcon,
        breadcrumbs: false
      },   
      {
        id: 'money-callbacks',
        title: 'Money Callbacks',
        type: 'item',
        url: '/money-callbacks',
        icon: icons. SettingsBackupRestoreOutlinedIcon,
        breadcrumbs: false
      }, 
      {
        id: 'pricing',
        title: 'Pricing',
        type: 'item',
        url: '/pricing',
        icon: icons. PriceChangeOutlinedIcon,
        breadcrumbs: false
      },
  ]
};

export default menuItems;
